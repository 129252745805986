import React from 'react';
import { Text, View, Image, TouchableOpacity, StyleSheet, ScrollView, Modal, findNodeHandle, UIManager } from 'react-native';

import styleHelper from '../Styles/StyleHelper';

import * as Navigation from '../Routes/Routing';

import ImageBackground from '../Components/ImageBackground';
import ImageProduct from '../Components/ImageProduct';
import ProductModal from '../Components/ProductModal';

import Device from '../Utils/Device';
import Config from '../Utils/Config';
import Services from '../Utils/Services';

import MenuStore from '../Stores/MenuStore';
import CartStore from '../Stores/CartStore';
import StoreStore from '../Stores/StoreStore';
import OrderStore from '../Stores/OrderStore';

import MenuActions from '../Actions/MenuActions';
import OrderActions from '../Actions/OrderActions';

var RCTUIManager = UIManager;

const styles = StyleSheet.create({
  contentBox: {
    // marginTop: Config.isJjk ? Device.getSize(40) : Device.getSize(5),
    // paddingHorizontal: Config.isJjk ? Device.getSize(20) : 0,
    marginTop: Device.getSize(10),
    flex: 2,
  },
  shadow: {
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 3,
      height: 5
    },
    shadowRadius: 5,
    shadowOpacity: 0.5
  },
  menuContainer: {
    minWidth: Device.getSize(160),
    maxWidth: Device.getSize(160),
    minHeight: Device.getSize(225),
    flex: 1,
    marginLeft: Device.getSize(20),
    marginRight: Device.getSize(-20)
  },
  logo: {
    width: Device.getSize(25),
    height: Device.getSize(25),
    position: 'absolute'
  },
  banner: {
    width: Device.width,
    height: Device.getSize(160),
    minHeight: Device.getSize(160),
    maxHeight: Device.getSize(160),
    marginTop: Device.getSize(-10),
    flex: 1
  },
  bannerImg: {
    width: Device.getSize(140),
    height: Device.getSize(170),
    position: 'absolute',
    left: Device.getSize(30),
    top: Device.getSize(0)
    // top: Device.getSize(20)
  },
  blockText: {
    marginTop: Device.getSize(12),
  },
  categoryContainer: {
    maxHeight: Device.getSize(125),
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    marginLeft: Device.getSize(15),
    marginRight: Device.getSize(0),
    marginBottom: Device.getSize(13),
    marginTop: Device.getSize(3),
  },
  orderTypeContainer: {
    minHeight: Device.getSize(35),
    minWidth: Device.getSize(120),
    marginBottom: Device.getSize(10),
  },
  subcategoryContainer: {
    maxHeight: Device.getSize(25),
  },
  categoryName: {
    fontSize: Device.getSize(15),
    textAlign: 'center',
    marginTop: Device.getSize(2),
  },
  borderActive: {
    borderBottomWidth: 3
  },
  shadowActive: {
    backgroundColor: 'white',
    shadowColor: Config.isTss ? '#FF8766' : Config.isJjk ? '#498674' : '#FF8766',
    // shadowColor: '#000',
    shadowOffset: {
      width: 3,
      height: 5
    },
    shadowRadius: 5,
    shadowOpacity: 0.5
  },
  roundedBorder: {
    borderRadius: Device.getSize(10),
    paddingVertical: Device.getSize(7),
    paddingHorizontal: Device.getSize(10),
  },
  borderBottom: {
    position: 'absolute',
    width: '40%',
    height: Device.getSize(1),
    // marginTop: Device.getSize(3),
    left: '30%',
    bottom: -10,
    border: '1px solid #A1412B'
  },
  subcontainer: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: Device.getSize(15),
    marginRight: Device.getSize(0),
    marginBottom: Device.getSize(0),
    marginTop: Device.getSize(0),
    // paddingVertical: Device.getSize(5),
    // paddingHorizontal: Device.getSize(15),
    // margin: Device.getSize(10),
    maxHeight: Device.getSize(85)
  },
  subDescription: {
    width: Device.getSize(300),
    height: Device.getSize(150),
    position: 'absolute',
    right: Device.getSize(25),
    top: Device.getSize(10)
  },
  pickerImage: {
    width: Device.getSize(110),
    height: Device.getSize(35),
  },
  instruction: {
    color: '#FF5100',
    fontSize: Device.getSize(16),
    // fontFamily: 'Recoleta-Bold'
  },
  subpickerContainer: {
    minWidth: Device.getSize(150),
    maxWidth: Device.getSize(150),
    flex: 1,
    marginLeft: Device.getSize(20),
    marginRight: Device.getSize(-10),
    marginBottom: Device.getSize(10)
  },
  subpickerImageContainer: {
    borderRadius: Device.getSize(10),
    paddingTop: Device.getSize(10),
    paddingBottom: Device.getSize(20),
    marginBottom: Device.getSize(3),
    marginTop: Device.getSize(5),
    maxHeight: Device.getSize(125),
  },
  greyishBlueBg: {
    backgroundColor: '#d1d1dd'
  },
  lightOrangeBg: {
    backgroundColor: '#fee1d1'
  },
  subpickerImage: {
    width: Device.getSize(90),
    height: Device.getSize(55),
  },
  breadcum: {
    marginBottom: Device.getSize(5),
    marginTop: Device.getSize(5),
    marginLeft: Device.getSize(25),
    marginRight: Device.getSize(20),
    flex: 1,
    flexDirection: 'row',
    height: Device.getSize(40),
    // width: Device.getSize(300)
  },
  blurBg: {
    width: Device.width,
    height: Device.height,
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  textRight: {
    textAlign: 'right'
  },
  cartContainer: {
    minHeight: Device.getSize(200),
    maxHeight: Device.getSize(550),
    overflow: 'hidden',
    marginTop: Device.getSize(10),
    marginRight: Device.getSize(20),
    borderRadius: Device.getSize(10),
    shadowColor: '#000',
    shadowOffset: {
      width: 3,
      height: 4
    },
    shadowRadius: 5,
    shadowOpacity: 0.5,
  },
  cartHeader: {
    borderTopLeftRadius: Device.getSize(10),
    borderTopRightRadius: Device.getSize(10),
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(5),
  },
  cartSection: {
    position: 'absolute',
    // top: Device.height * Device.getRatio(0.45),
    top: Device.height * Device.getRatio(0.55),
    backgroundColor: '#FFF',
    height: Device.getSize(150),
    width: Device.width,
    paddingTop: Device.getSize(10),
    paddingHorizontal: Device.getSize(10),
  },
  cartButton: {
    paddingVertical: Device.getSize(8),
    paddingHorizontal: Device.getSize(5),
    borderRadius: Device.getSize(5),
  },
  cartQtyBox: {
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(5),
    width: Device.getSize(30),
    textAlign: 'center',
    backgroundColor: Colors.HeavyOrange,
  },
  spaceHorizontal: {
    paddingHorizontal: Device.getSize(10),
  },
  spaceVerticalSmall: {
    paddingVertical: Device.getSize(5)
  },
  spaceVertical: {
    paddingVertical: Device.getSize(10)
  },
  spaceVerticalBig: {
    paddingVertical: Device.getSize(20)
  },
  btnCheckout: {
    borderRadius: Device.getSize(10),
  },
  customBtn: {
    borderRadius: Device.getSize(10),
    minWidth: Device.getSize(100),
    maxHeight: Device.getSize(40),
    marginTop: Device.getSize(10),
    marginHorizontal: Device.getSize(5),
    paddingHorizontal: Device.getSize(20)
  },
  button: {
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(10),
    // paddingHorizontal: Device.getSize(5),
    borderRadius: Device.getSize(5),
    marginRight: Device.getSize(5),
    // marginBottom: Device.getSize(10)
    marginBottom: Device.getSize(5)
  },
});

const productStyles = StyleSheet.create({
  menuContainer: {
    minHeight: Device.getSize(120),
  },
  container: {
    borderRadius: Device.getSize(5),
    backgroundColor: '#FFF',
    marginHorizontal: Device.getSize(20),
    paddingHorizontal: Device.getSize(5),
    paddingTop: Device.getSize(10),
    paddingBottom: Device.getSize(10),
    marginBottom: Device.getSize(2),
  },
  popupContainer: {
    width: Device.width * 0.7,
    height: Device.getSize(150),
    backgroundColor: '#FFF',
    paddingVertical: Device.getSize(10),
    marginTop: Device.height * 0.4,
    marginBottom: Device.getSize(20),
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: Device.getSize(10)
  },
});

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.onCartChange = this.onCartChange.bind(this);
    this.state = {
    };
  }

  componentDidMount() {
    CartStore.addChangeProductCart(this.onCartChange);
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
  }

  onCartChange() {
    setTimeout(() => {
      MenuActions.updateMenuSection();
    }, 500);
  }

  render() {
    return (
      <ImageBackground>
        <View style={[]}>
          <MenuDetails />
        </View>
      </ImageBackground>
    )
  }
}

class MenuDetails extends React.Component {
  constructor(props) {
    super(props);

    this.onCartChange = this.onCartChange.bind(this);
    this.onVariantChange = this.onVariantChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.sortProducts = this.sortProducts.bind(this);
    this.navigateWelcome = this.navigateWelcome.bind(this);
    this.navigateToProduct = this.navigateToProduct.bind(this);
    this.refreshView = this.refreshView.bind(this);
    this.applyTssCustomModifier = this.applyTssCustomModifier.bind(this);
    this.renderTopNav = this.renderTopNav.bind(this);
    this.renderSubCategory = this.renderSubCategory.bind(this);
    this.renderPopupMsg = this.renderPopupMsg.bind(this);
    this.renderProducts = this.renderProducts.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.onProductClose = this.onProductClose.bind(this);
    this.checkModifierValid = this.checkModifierValid.bind(this);
    this.onModifierChange = this.onModifierChange.bind(this);
    this.toggleCustomModifiers = this.toggleCustomModifiers.bind(this);
    this.hidePopupMsg = this.hidePopupMsg.bind(this);

    this.state = {
      categories: MenuStore.getMultipleCategories(StoreStore.getCategoryId()),
      sections: MenuStore.getMenuDetailsSections(),
      activeIndex: 0,
      nodes: new Map(),
      x: 0,
      y: 0,
      positions: [],
      subPositions: [],
      grandTotal: CartStore.getGrandTotal(),
      showProduct: false,
      productSubtotal: OrderStore.getCurrentSubtotal(),
      modifierGroups: [],
      modifierGroupsValid: [],
      imgWidth: 0,
      imgHeight: 0,
      products: CartStore.getServerProducts(),
      cartQuantity: CartStore.getQuantity(),
      showPopupMsg: false,
      msg: '',
    };
  }

  componentDidMount() {
    CartStore.addChangeProductCart(this.onCartChange);
    OrderStore.addChangeVariantListener(this.onVariantChange);

    let pos, pos2;
    let categories = this.state.categories;
    Services.onFocusCallback(() => {
      pos = this.state.positions;
      pos2 = this.state.subPositions;

      try {
        categories.forEach((category, i) => {
          this.state.sections[category.id].subcategories.forEach((subcategory, j) => {
            if (subcategory.products) {
              // menu scrollbar
              const node = this.state.nodes.get(subcategory.id);
              const position = findNodeHandle(node);
              RCTUIManager.measure(position, (fx, fy, w, h, px, py) => {
                pos.push({
                  id: subcategory.id,
                  y: py
                });
              });

              // sub category slider
              const node2 = this.state.nodes.get('00' + subcategory.id);
              const position2 = findNodeHandle(node2);
              RCTUIManager.measure(position2, (fx, fy, w, h, px, py) => {
                pos2.push({
                  id: '00' + subcategory.id,
                  x: px
                });
              });
            }
          });
        });
      } catch (BreakException) {
      }

      this.setState({
        positions: pos,
        subPositions: pos2,
      });

    });
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
    OrderStore.removeChangeVariantListener(this.onVariantChange);
  }

  onVariantChange() {
    let modifierGroups = this.applyTssCustomModifier();
    this.setState({
      productSubtotal: OrderStore.getCurrentSubtotal(),
      modifierGroups: modifierGroups
    });
  }

  onCartChange() {
    setTimeout(() => { // make warning
      this.setState({
        sections: MenuStore.getMenuDetailsSections(),
        grandTotal: CartStore.getGrandTotal(),
        products: CartStore.getServerProducts(),
        cartQuantity: CartStore.getQuantity(),
      })
    }, 700);
  }

  handleScroll(event) {
    this.setState({
      y: event.nativeEvent.contentOffset.y
    });

    // sort from highest y
    this.state.positions.sort(function (a, b) {
      return b.y - a.y;
    });

    try {
      this.state.positions.forEach((position, i) => {
        if (event.nativeEvent.contentOffset.y + 360 >= position.y) {
          let index = this.state.positions.length - (i + 1);
          // change active subcategory
          this.setState({
            activeIndex: index
          });
          this.subScroll.scrollTo({ x: this.state.subPositions[index].x - 10, y: 0, animated: true });

          // break iteration
          throw BreakException;
        }
      });
    } catch (e) {

    }
  }

  sortProducts(products) {
    let sortedProducts = [];
    let productChunk = [];
    let orderType = Services.parseOrderType(OrderStore.getType());

    products.map((product, i) => {
      if (product[orderType] == true) {
        if (i % 1 === 0) {
          sortedProducts.push(productChunk);
          productChunk = [];
          productChunk.push(product);
        } else {
          productChunk.push(product);
        }
      }
    });
    sortedProducts.push(productChunk);
    return sortedProducts;
  }

  // apply TSS custom modifier logic
  applyTssCustomModifier() {
    let modifierMaps = MenuStore.getModifierMap();
    let modifierGroups = OrderStore.getModifierGroups();
    let newModifierGroups = [];
    modifierGroups.forEach((modifierGroup) => {
      modifierGroup.hide = false;
      modifierGroup.modifier1 = 0;

      // DB was designed for multi modifiers, but temporarily treat as single modifier
      // apply_to_show was currently unused bcos leak of time to do (urgently needed)
      let qty = 0;
      modifierMaps.forEach(modifierMap => {
        if (modifierGroup.id == modifierMap.modifier2 && modifierMap.apply_to_show) {
          qty = 0;
          // check if parent selected
          modifierGroups.forEach((mg) => {
            if (mg.id == modifierMap.modifier1) {
              mg.product_modifier_ids.forEach(m => {
                qty += m.quantity;
              });
            }
          });

          if (qty < 1) {
            modifierGroup.hide = true; // hide if parent not selected
          } else {
            modifierGroup.hide = false; // show if parent selected
          }
          modifierGroup.modifier1 = modifierMap.modifier1;
        }
      });
      newModifierGroups.push(modifierGroup);
    });

    return newModifierGroups;
  }

  onProductClose(msg) {
    this.setState({
      showProduct: false,
      modifierGroupsValid: [true, true, true, true, true, true],
    });

    if (msg) {
      this.setState({
        showPopupMsg: true,
        msg: msg
      });
    }
  }

  checkModifierValid() {
    this.setState({
      modifierGroupsValid: OrderStore.checkModifierGroupsValid(),
    });
  }

  onModifierChange(modifierGroupsValid) {
    this.setState({
        modifierGroupsValid: modifierGroupsValid,
    });
  }

  toggleCustomModifiers(modifierGroup, hide) {
    let newMG = [];
    let parentModifier = 0;
    this.state.modifierGroups.forEach((mg) => {
        // recursive call for children
        if (parentModifier != 0) {
            this.state.modifierGroups.forEach((mg2) => {
                if (parentModifier == mg2.modifier1) {
                    parentModifier = 0;
                    this.toggleCustomModifiers({ id: mg2.id }, hide);
                }
            });
        }

        if (mg.id == modifierGroup.id) {
            if (hide) {
                parentModifier = mg.id;

                OrderActions.resetModifierGroup(mg);
            } else {
                mg.hide = hide;
            }
        }

        newMG.push(mg);
    });

    this.setState({
        modifierGroups: newMG
    });
  }

  hidePopupMsg() {
    this.setState({
      showPopupMsg: false,
    });
  }

  navigateWelcome() {
    Navigation.navigate('Welcome');
  }

  navigateToProduct(product) {
    OrderStore.setSelectedProduct(product);

    let modifierValids = [];
    OrderStore.getModifierGroups().forEach((modifier, i) => {
      modifierValids.push(true);
    });

    let modifierGroups = this.applyTssCustomModifier();

    Image.getSize(Services.getImageURL(product.id, 'product'), (imageWidth, imageHeight) => {
      let scaledImage = Services.getImageScaledSize(imageWidth, imageHeight, Device.width * 0.9);
      this.setState({
        showProduct: true,
        productSubtotal: OrderStore.getCurrentSubtotal(),
        modifierGroups: modifierGroups,
        modifierGroupsValid: modifierValids,
        imgWidth: scaledImage.width,
        imgHeight: scaledImage.height
      })
    }, () => {
      this.setState({
        showProduct: true,
        productSubtotal: OrderStore.getCurrentSubtotal(),
        modifierGroups: modifierGroups,
        modifierGroupsValid: modifierValids,
      });
    });
  }

  refreshView(index, id) {
    // Scroll to subcategory
    let offset = Device.getSize(-200);
    try {
      const node = this.state.nodes.get(id);
      const position = findNodeHandle(node);
      RCTUIManager.measure(position, (fx, fy, w, h, px, py) => {
        this.setState({
          y: this.state.y + py + offset
        });
        this.myScroll.scrollTo({ x: 0, y: this.state.y + py + offset, animated: true });
      });
    } catch (BreakException) {

    }
  }

  renderSubCategory(sections) {
    let len = 0;
    let index = 0;
    let categoryId;
    let categories = this.state.categories;
    return (
      <ScrollView horizontal={true} style={[styleHelper.borderBottomWhiteTransparent, { paddingTop: Device.getSize(10) }]}
        ref={(ref) => {
          this.subScroll = ref
        }}>
        <View style={[{ flexDirection: 'row' }]}>
          {
            categories.map((category, j) => {
              if (j > 0) {
                len += sections[categoryId].subcategories.length;
              }
              categoryId = category.id;
              return (
                <View key={j} style={[{ flexDirection: 'row' }]}>
                  {
                    sections[category.id].subcategories.map((section, i) => {
                      if (section.products) {
                        let sortedProducts = this.sortProducts(section.products);
                        if (sortedProducts.length > 1) {
                          index = i;
                          if (j > 0) {
                            index = len + i;
                          }

                          return (
                            <TouchableOpacity
                              onPress={() => { this.refreshView(i, section.id) }}
                              key={i}
                              style={[styles.subcategoryContainer, styles.container]}
                              ref={(ref) => {
                                this.state.nodes.set('00' + section.id, ref);
                              }}>
                              <Text style={[styles.categoryName, styleHelper.OrangeDarkFont, styleHelper.fontHelveticaNeueBold, index == this.state.activeIndex && styleHelper.DarkRedFont]}>
                                {section.name}
                              </Text>

                              <View style={[index == this.state.activeIndex && styles.borderBottom]} />
                            </TouchableOpacity>
                          )
                        } else {
                          return (
                            <View key={i}
                              ref={(ref) => {
                                this.state.nodes.set('00' + section.id, ref);
                              }} />
                          )
                        }
                      }
                    })
                  }
                </View>
              )
            })
          }
        </View>
      </ScrollView>
    )
  }

  renderPopupMsg() {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.showPopupMsg}>

        <View style={[styleHelper.flex1, styles.blurBg, { alignItems: 'center' }]}>
          <View style={[productStyles.popupContainer]}>
            <View style={[styleHelper.flexRow, styleHelper.borderBottomBlackTransparent, styleHelper.flexCenter,
            { height: Device.getSize(100), marginBottom: Device.getSize(10), paddingHorizontal: Device.getSize(10) }]}>
              <Text style={[styleHelper.fontRegular, styleHelper.font14, styleHelper.fontBlack, styleHelper.textCenter]}>
                {this.state.msg}
              </Text>
            </View>

            <TouchableOpacity
              onPress={() => this.hidePopupMsg()}
              style={[styleHelper.flexCenter]}>
              <Text style={[styleHelper.font14, styleHelper.fontRegular, styleHelper.fontOrange]}>
                Ok
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    )
  }

  // Menu Products List
  renderProducts(products) {
    return (
      <View>
        {
          products.map((sortedProduct, j) => (
            sortedProduct.length > 0 &&
            <View key={j}>
              {
                sortedProduct.map((product, i) => (
                  <View style={[productStyles.container]} key={i}>
                    <TouchableOpacity
                      activeOpacity={Config.activeOpacity}
                      onPress={() => this.navigateToProduct(product)}
                      style={[productStyles.menuContainer]}>

                      <View style={[styleHelper.flex1, styleHelper.flexRowSpaceBetween]}>
                        <View style={[styleHelper.flex3, { marginRight: Device.getSize(5) }]}>
                          <View style={[styleHelper.containerText, { marginLeft: Device.getSize(2) }]}>
                            <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font15]}>
                              {product.name}
                            </Text>
                          </View>

                          <View style={[styleHelper.containerText, { marginLeft: Device.getSize(2) }]}>
                            <Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font13, { color: '#666' }]}>
                              {product.description_sale != null ? product.description_sale.substring(0, 80) + '...' : null}
                            </Text>
                          </View>

                          <View style={[styleHelper.containerText, { marginLeft: Device.getSize(2) }]}>
                            {
                              product.list_price === 0 &&
                              <Text
                                style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font15]}>
                                {
                                  product.product_variant_ids.length == 0 &&
                                  <Text style={[styleHelper.fontHelveticaNeueRegular]}>
                                    ${Math.round(product.list_price * 100) / 100}
                                  </Text>
                                }

                                {
                                  product.product_variant_ids.length > 1 &&
                                  <Text style={[styleHelper.fontHelveticaNeueRegular]}>
                                    Start From ${Math.round(product.product_variant_ids[0].lst_price * 100) / 100}
                                  </Text>
                                }
                              </Text>
                            }
                            {
                              product.list_price > 0 &&
                              <Text
                                style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font13]}>
                                ${Math.round(product.list_price * 100) / 100}
                              </Text>
                            }
                          </View>
                        </View>

                        {/* <View style={[styleHelper.flex2]}> */}
                        <ImageProduct
                          imageID={product.id}
                          width={Device.getSize(110)}
                          height={Device.getSize(110)} />
                        {/* </View> */}
                      </View>

                    </TouchableOpacity>
                  </View>
                ))
              }
            </View>
          ))
        }
      </View>
    )
  }

  renderContent(sections) {
    let categories = this.state.categories;
    let sortedProducts;

    return (
      <View style={[styles.contentBox, { minHeight: Device.height * 0.83 }]}>
        <ScrollView ref={(ref) => {
          this.myScroll = ref
        }}
          scrollEventThrottle={0}
          style={[{ width: Device.width, height: Device.height * 0.83 }]}
          onScroll={this.handleScroll}>
          {
            categories.map((category, i) => {
              return (
                <View key={i}>
                  {
                    sections[category.id].subcategories.map((subcategory, j) => {
                      if (subcategory.products) {
                        sortedProducts = this.sortProducts(subcategory.products);

                        if (sortedProducts.length > 1) {
                          return (
                            <View key={j}>
                              <View style={[{ marginBottom: Device.getSize(30) }]}>
                                <View style={[{ marginLeft: Device.getSize(20), marginBottom: Device.getSize(10) }]}>
                                  <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font18, styleHelper.DarkRedFont]}
                                    ref={(ref) => {
                                      this.state.nodes.set(subcategory.id, ref);
                                    }}
                                  >
                                    {subcategory.name}
                                  </Text>
                                </View>
                                {this.renderProducts(sortedProducts)}
                              </View>
                            </View>
                          )
                        } else {
                          return (
                            <View key={j} />
                          )
                        }
                      }
                    })
                  }
                </View>
              )
            })
          }
        </ScrollView>
      </View>
    )
  }

  renderTopNav() {
    return (
      <View style={[styleHelper.flexRow, { backgroundColor: '#ca6d4f', height: Device.getSize(50) }]}>
        <View style={[styleHelper.flex1, { marginVertical: Device.getSize(15), marginLeft: Device.getSize(10) }]}>
          <TouchableOpacity onPress={() => { this.navigateWelcome(); }}>
            <Text style={[styleHelper.fontWhite, styleHelper.font15, styleHelper.fontHelveticaNeueBold]}>
              &lt;
            </Text>
          </TouchableOpacity>
        </View>

        <View style={[styleHelper.flex1, styleHelper.flexCenter]}>
          <Text style={[styleHelper.fontWhite, styleHelper.font16, styleHelper.fontHelveticaNeueBold]}>
            MENU
          </Text>
        </View>

        <View style={[styleHelper.flex1]} />
      </View>
    )
  }

  render() {
    return (
      <View>
        <ProductBox
          show={this.state.showProduct}
          imgWidth={this.state.imgWidth}
          imgHeight={this.state.imgHeight}
          productSubtotal={this.state.productSubtotal}
          modifierGroups={this.state.modifierGroups}
          modifierGroupsValid={this.state.modifierGroupsValid}
          checkModifierValid={this.checkModifierValid}
          onModifierChange={this.onModifierChange}
          toggleCustomModifiers={this.toggleCustomModifiers}
          onProductClose={this.onProductClose} />

        {this.renderPopupMsg()}

        <View>
          {this.renderTopNav()}
          {this.renderSubCategory(this.state.sections)}
        </View>

        <View style={[{ minHeight: Device.height * 0.8 }]}>
          {/* <ImageBackground style={[styleHelper.flexRow, styleHelper.flex1]}> */}
          {
            // !this.state.showPayment &&
            this.renderContent(this.state.sections)
          }
          {/* {
              this.state.showPayment &&
              this.renderPayment()
            } */}
          {/* {this.renderCart()} */}
          {
            <Cart />
          }
          {/* </ImageBackground> */}
        </View>
      </View>
    )
  }
}

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.onCartChange = this.onCartChange.bind(this);
    this.state = {
      grandTotal: CartStore.getGrandTotal(),
    };
  }

  componentDidMount() {
    CartStore.addChangeProductCart(this.onCartChange);
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
  }

  onCartChange() {
    setTimeout(() => {
      this.setState({
        grandTotal: CartStore.getGrandTotal(),
      });
    }, 500);
  }

  navigateToCart() {
    if (CartStore.hasProducts() === true) {
      Navigation.navigate('Cart');
    } else {
      Services.showAlertError('There are currently no items in your cart.');
    }
  }

  render() {
    let itemCount = CartStore.getQuantity();
    if (this.state.grandTotal <= 0 && itemCount <= 0) {
      return <View />
    }

    return (
      <View style={[styles.cartSection]}>
        <TouchableOpacity
          onPress={() => this.navigateToCart()}
          activeOpacity={Config.activeOpacity}
          style={[styleHelper.flexRow, styleHelper.bgOrange, styles.cartButton]}>
          <View style={[styleHelper.flex1]}>
            <Text
              style={[
                styleHelper.fontBold,
                styleHelper.fontWhite,
                styleHelper.font16,
                styles.cartQtyBox
              ]}>
              {itemCount}
            </Text>
          </View>

          <View style={[styleHelper.flexCenter, styleHelper.flex1]}>
            <Text
              style={[
                styleHelper.fontBold,
                styleHelper.fontWhite,
                styleHelper.font16,
              ]}>
              View Basket
            </Text>
          </View>

          <View style={[styleHelper.flex1, { paddingTop: Device.getSize(5) }]}>
            <Text
              style={[
                styleHelper.fontBold,
                styleHelper.fontWhite,
                styleHelper.font16,
                { textAlign: 'right' }
              ]}>
              {Services.formatPrice(this.state.grandTotal)}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}

const ProductBox = (props) => {
  if (!props.show) {
    return <View />
  }

  return (
    <ProductModal
      show={props.show}
      imgWidth={props.imgWidth}
      imgHeight={props.imgHeight}
      productSubtotal={props.productSubtotal}
      modifierGroups={props.modifierGroups}
      modifierGroupsValid={props.modifierGroupsValid}
      checkModifierValid={props.checkModifierValid}
      onModifierChange={props.onModifierChange}
      toggleCustomModifiers={props.toggleCustomModifiers}
      onProductClose={props.onProductClose} />
  )
}
