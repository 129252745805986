import {ListView} from 'react-native';

import DeviceInfo from 'react-native-device-info';

import Device from '../Utils/Device';
import Colors from '../Styles/Colors';

const heightNavBar =  Device.isAndroid === true ? Device.getSize(54) :Device.getSize(14);
const keyboardMargin = Device.isIOS === true ? Device.getSize(80):Device.getSize(130);

const Config = {
	errorTitle: 'Oops!',
	activeOpacity: 0.7,
	// hitslopSmall: {top: Device.getSize(28), bottom: Device.getSize(28), left: Device.getSize(28), right: Device.getSize(28)},
	hitslopSmall: {top: Device.getSize(18), bottom: Device.getSize(18), left: Device.getSize(28), right: Device.getSize(28)},
	marginTopNav: Device.getSize(60),
	heightStatusBar: Device.getSize(20),
	heightNavBar: heightNavBar,
	contentHeight: Device.height - heightNavBar,
	keyboardMargin: keyboardMargin,
	input: {
		autoCapitalize: 'none',
		autoCorrect: false,
		underlineColorAndroid: 'rgba(0,0,0,0)',
	},
	lightStatusBar: {
		barStyle: 'light-content',
		backgroundColor: Colors.darkStatusBar,
		hidden: true
	},
	darkStatusBar: {
		barStyle: Device.isIOS === true ? 'dark-content': 'default',
		backgroundColor: Colors.darkStatusBar,
		hidden: true
	},
	orangeStatusBar: {
		backgroundColor: Colors.Orange
	},
	keyboardAvoid: {
		behavior: 'position',
		keyboardVerticalOffset: Device.isAndroid === true ? Device.getSize(25):0
	},
	keyboardAvoidLogin: {
		behavior: 'position',
		keyboardVerticalOffset: Device.isAndroid === true ? Device.getSize(50):Device.getSize(10)
	},
	deviceID: '',
	mapZoom: 0.05,
	initialZoom: 0.3,
	coordSG: {
		latitude: 1.3424739,
		longitude: 103.8293791,
	},
	formatDateTime: 'MMM DD, YYYY h:mm A',
	cartSummaryDateTimeFormat: 'dddd MMM DD, YYYY h:mm A',
	formatDate: 'MMM DD, YYYY',
	formatDateTimeText: 'DD/MM/YYYY, hh:mm',
	formatTime: 'h:mm A',
	formatYMD: 'YYYY/MM/DD',
	formatSuperholic: 'DD MMM YYYY',
	url_image: 'https://mp2pos.thesoupspoon.com/web/image?',
	imageSizeLandscape: {
		width: Device.getSize(150),
		height: Device.getSize(95),
	},
	thresholdLazyLoad: 200, // margin in pixels to bottom to call next page
	// listviewByID: new ListView.DataSource({rowHasChanged: (r1, r2) => r1.id !== r2.id}),
	isProductionEnv: true,
	isTss: true,
	isTgk: false,
	isJjk: false,
	timezone: -8,
	gst: 9,
	versionNo: '1.1.1',
}

// module.exports = Config;
export default Config
