import React from 'react';
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from 'react-native';

import * as Navigation from '../Routes/Routing';

import ProductOrder from '../Components/ProductOrder';

import Config from '../Utils/Config';
import Device from '../Utils/Device';
import API from '../Utils/API';

import styleHelper from '../Styles/StyleHelper';

import StoreStore from '../Stores/StoreStore';

const styles = StyleSheet.create({

});

export default class MyOrder extends React.Component {
  constructor(props) {
    super(props);
    this.navigateHome = this.navigateHome.bind(this);
    this.state = {
        orderExist: false,
        order: null,
        subtotal: 0,
        total: 0,
        discount: null
    }
  }

  componentDidMount() {
    API.getOrder(StoreStore.getQRToken(), {
        success: (res)=>{
            if(res.error) {
                Navigation.navigate('Expired');
            } else {
                let items = [];
                let modifiers = [];
                res.lines.forEach((line)=>{
                    modifiers = [];
                    line.modifier_lines.forEach((modifier_line)=>{
                        modifiers.push({
                            modifierName: modifier_line.name,
                            quantity: modifier_line.qty,
                            price: modifier_line.price
                        });
                    })
                    items.push({
                        productName: line.name,
                        quantity: line.qty,
                        productPrice: line.price,
                        modifiers: modifiers
                    });
                })
    
                if(res.lines.length > 0) {
                    this.setState({
                        subtotal: res.subtotal,
                        total: res.total,
                        discount: res.discount,
                        order: {
                            items: items,
                            extraCharges: [{
                                string: 'Service Charge',
                                percentage: 10,
                                amount: res.service_charge
                            },
                            {
                                string: 'GST',
                                percentage: Config.gst,
                                amount: res.gst
                            }]
                        },
                        orderExist: true
                    })
                }
            }
        },
        error: (err)=>{
        }
    })
  }

  navigateHome() {
    Navigation.navigate('Welcome');
  }

  render() {
    return(
        <View style={[styleHelper.backgroundImage, {backgroundColor: '#f8f5f0'}]}>
            <View style={[styleHelper.flexRow, {backgroundColor: '#ca6d4f', height: Device.getSize(50)}]}>
                <View style={[styleHelper.flex1, {marginVertical: Device.getSize(15), marginLeft: Device.getSize(10)}]}>
                    <TouchableOpacity onPress={()=>{ this.navigateHome(); }}>
                        <Text style={[styleHelper.fontWhite, styleHelper.font15, styleHelper.fontHelveticaNeueBold]}>
                            &lt;
                        </Text>
                    </TouchableOpacity>
                </View>

                <View style={[styleHelper.flex1, styleHelper.flexCenter]}>
                    <Text style={[styleHelper.fontWhite, styleHelper.font16, styleHelper.fontHelveticaNeueBold]}>
                        MY ORDER
                    </Text>
                </View>

                <View style={[styleHelper.flex1]} />
            </View>

            <View>
                {!this.state.orderExist && (
                    <View style={[styleHelper.flexCenter]}>
                        <Image
                            source={require('../Img/ic_no_order.png')}
                            resizeMode={'contain'}
                            style={[styleHelper.flexCenter, {width: Device.getSize(80), height: Device.getSize(80), marginTop: Device.getSize(50)}]} />

                        <Text style={[styleHelper.font13, styleHelper.fontHelveticaNeueRegular, {color: '#666'}]}>
                            You have not placed any order yet
                        </Text>
                    </View>
                )}

                {this.state.orderExist && (
                    <View style={[{marginTop: Device.getSize(20)}]}>
                        <View style={[styleHelper.flex1, styleHelper.flexRow, styleHelper.borderBottomBlackTransparent, {paddingBottom: Device.getSize(20), paddingHorizontal: Device.getSize(10)}]}>
                            <Text style={[styleHelper.fontBold, styleHelper.font18, styleHelper.fontBlack]}>
                                {StoreStore.getTableNo()}
                            </Text>
                        </View>

                        <View style={[styleHelper.flexRow, {width: Device.width}]}>
                            <View style={[{width: Device.width * 0.95}]}>
                                <ScrollView persistentScrollbar={true} style={[{maxHeight: Device.height * 0.83}]}>
                                    <ProductOrder
                                        order={this.state.order}
                                        subTotal={this.state.subtotal}
                                        discount={this.state.discount}
                                        total={this.state.total} />
                                </ScrollView>
                            </View>

                            <View style={[{width: Device.getSize(1),
                                        height: Device.height * 0.7,
                                        marginRight: Device.getSize(5),
                                        marginTop: Device.getSize(5),
                                        borderRadius: Device.getSize(10),
                                        borderColor: '#CCC',
                                        borderWidth: 1,
                                        borderStyle: 'solid'}]} />
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
  }
}
