import React from 'react';
import {
  Text,
  View,
} from 'react-native';

import * as Navigation from '../Routes/Routing';

import styleHelper from '../Styles/StyleHelper';

import SmallSpinner from '../Components/SmallSpinner';
import ImageBackground from '../Components/ImageBackground';

import Config from '../Utils/Config';
import Services from '../Utils/Services';
import API from '../Utils/API';

import StoreActions from '../Actions/StoreActions';
import OrderActions from '../Actions/OrderActions';
import MenuActions from '../Actions/MenuActions';

import StoreStore from '../Stores/StoreStore';
import OrderStore from '../Stores/OrderStore';

export default class Splash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: true,
    };
  }

  componentDidMount() {
    Services.onFocusCallback(() => {
      let today = new Date();
      let offset = today.getTimezoneOffset() / 60;
      if (offset != Config.timezone && Config.isProductionEnv) {
        Navigation.navigate('Offset');
        return;
      } else {
        StoreActions.getData({
          complete: () => {
            this.setState({
              showSpinner: false
            });
          }
        });
      }
    });
  }

  render() {
    if (this.state.showSpinner) {
      return (
        <ImageBackground>
          <View style={[styleHelper.containerTopFit, styleHelper.flexCenter]}>
            <Text style={[styleHelper.OrangeDarkFont, styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter]}>
              Fun sips, quips and bites...
            </Text>
            <SmallSpinner />
          </View>
        </ImageBackground>
      );
    }

    return (
      <LoadMenu />
    )
  }
}

class LoadMenu extends React.Component {
  constructor(props) {
    super(props);
    this.setStore = this.setStore.bind(this);
    this.setTypeStore = this.setTypeStore.bind(this);
    this.setBookingToStores = this.setBookingToStores.bind(this);
    this.navigateHome = this.navigateHome.bind(this);

    const urlParams = new URLSearchParams(window.location.search);
    const table_id = urlParams.get('table_id');
    const summary_ip = urlParams.get('summary_ip');
    const session_id = urlParams.get('pos_session');
    const user_id = urlParams.get('user_id');
    let token = '';
    
    if(Config.isProductionEnv) {
      token = urlParams.get('token');
      if (!token || !table_id || !summary_ip || !session_id || !user_id) {
        Navigation.navigate('Lost');
      }  
    } else {
      if (!table_id || !summary_ip || !session_id || !user_id) {
        // invalid url
        Navigation.navigate('Lost');
      }
    }

    this.state = {
      storeId: 18,
      posStoreCode: 1067,
      categoryId: '',
      configId: 0,
      tableId: table_id,
      token: token,
      summaryIp: summary_ip,
      sessionId: session_id,
      pricelistId: 1,
      userId: user_id,
    };
  }

  componentDidMount() {
    if(Config.isProductionEnv) {
      API.getOrder(this.state.token, {
        success: (res) => {
          if (res.error) {
            Navigation.navigate('Expired')
          } else {
            this.setStore('dinein', () => {
              setTimeout(() => {
                MenuActions.getData(StoreStore.getSelectedStore().id, () => {
                  this.navigateHome();
                });
              }, 1000);
            });
          }
        },
        error: () => {
          // err here
        }
      });
    } else {
      this.setStore('dinein', () => {
        setTimeout(() => {
          MenuActions.getData(StoreStore.getSelectedStore().id, () => {
            this.navigateHome();
          });
        }, 1000);
      });
    }
  }

  setStore(orderType, callback) {
    let oT = orderType;
    this.setTypeStore(oT);

    // Set Selected Store
    let selectedStore = StoreStore.getAllStoreForSelectedType().find((store) => store.id === this.state.storeId);
    if (selectedStore === undefined) {
      // Set Alternative Order Type
      oT = 'takeaway';
      this.setTypeStore(oT);
      selectedStore = StoreStore.getAllStoreForSelectedType().find((store) => store.id === this.state.storeId);
    }
    StoreActions.select(selectedStore, this.state.posStoreCode, this.state.categoryId, this.state.token, this.state.summaryIp, this.state.sessionId, this.state.pricelistId, this.state.userId, this.state.configId, this.state.tableId);

    OrderStore.setTableId(this.state.tableId);
    this.setBookingToStores(oT, this.state.tableId, callback);
  }

  setTypeStore(type) {
    if (StoreStore.checkStoreAvailableForType(type) == false) {
      return Services.showAlertError('Services temporarily unavailable', () => { });
    }

    StoreActions.setType(type);
    if (OrderStore.getType() !== type) {
      StoreStore.clear();
    }
  }

  setBookingToStores(orderType, tableId, callback) {
    OrderActions.setOrderType(orderType);
    OrderStore.setStore(StoreStore.getSelectedStore());

    if (orderType == 'dinein') {
      StoreActions.selectTable(tableId);
      OrderStore.setTable(StoreStore.getSelectedTable());
    } else if (orderType == 'takeaway') {
      // OrderStore.setEpochTime(StoreStore.getEpochTime());
    }

    callback();
  }

  navigateHome() {
    const urlParams = new URLSearchParams(window.location.search);
    const table_id = urlParams.get('table_id');
    const summary_ip = urlParams.get('summary_ip');
    const session_id = urlParams.get('pos_session');
    const user_id = urlParams.get('user_id');
    if(Config.isProductionEnv) {
      const token = urlParams.get('token');
      if (token && table_id && summary_ip && session_id && user_id) {
        Navigation.navigate('Welcome');
      }  
    } else {
      if (table_id && summary_ip && session_id && user_id) {
        Navigation.navigate('Welcome');
      }
    }
  }

  render() {
    return (
      <ImageBackground>
        <View style={[styleHelper.containerTopFit, styleHelper.flexCenter]}>
          <Text style={[styleHelper.OrangeDarkFont, styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter]}>
            Fun sips, quips and bites...
          </Text>
          <SmallSpinner />
        </View>
      </ImageBackground>
    );
  }
}