import React from 'react';
import {
  Text,
  View,
  Image,
  CheckBox,
  TouchableOpacity,
  ImageBackground,
  StyleSheet
} from 'react-native';

import * as Navigation from '../Routes/Routing';

import Config from '../Utils/Config';
import Device from '../Utils/Device';
import API from '../Utils/API';

import styleHelper from '../Styles/StyleHelper';

import OrderActions from '../Actions/OrderActions';

import StoreStore from '../Stores/StoreStore';

const productStyles = StyleSheet.create({
  customBtn: {
      backgroundColor: '#A2412B',
      borderColor: '#F5DBD3',
      borderWidth: 5,
      borderStyle: 'solid',
      borderRadius: Device.getSize(10),
      width: Device.width * 0.8,
      maxHeight: Device.getSize(50),
  },
  customBtn2: {
      backgroundColor: '#F5DBD3',
      borderColor: '#FAF7F1',
      borderWidth: 5,
      borderStyle: 'solid',
      borderRadius: Device.getSize(10),
      width: Device.width * 0.8,
      maxHeight: Device.getSize(30),
      marginTop: Device.getSize(10),
      marginBottom: Device.getSize(20),
},
});

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.navigateHome = this.navigateHome.bind(this);
    this.state = {
      orderExist: false,
      isCheck: false
    }
  }

  componentDidMount() {
    if(Config.isProductionEnv) {
      API.getOrder(StoreStore.getQRToken(), {
        success: (res)=>{
          if(res.lines.length > 0) {
            this.setState({
              orderExist: true
            })
          }
        },
        error: ()=>{
          
        }
      });
    }
  }

  navigateHome(orderType) {
    if(this.state.isCheck){
      OrderActions.setOrderType2(orderType);
      
      Navigation.navigate('Home');
    }
  }

  navigateMyOrder() {
    Navigation.navigate('MyOrder');
  }

  render() {
    return(
      <ImageBackground
        source={require('../Img/img_background.png')}
        resizeMode={'cover'}
        style={[styleHelper.backgroundImage]}>
          <View style={[styleHelper.flexCenter, {width: Device.width, marginTop: Device.height * 0.45}]}>
            <Text style={[styleHelper.OrangeDarkFont, styleHelper.font14, styleHelper.fontHelveticaNeueRegular, styleHelper.flexCenter]}>
                {StoreStore.getSelectedStore().address.line1}
            </Text>

            <Text style={[styleHelper.fontBlack, styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, {marginTop: Device.getSize(40)}]}>
                {StoreStore.getTableNo()}
            </Text>
          </View>

          <View style={[{width: Device.width, position: 'absolute', bottom: Device.getSize(20)}, styleHelper.flex1, styleHelper.flexCenter]}>
              <View style={[styleHelper.flexRow, styleHelper.flex1, styleHelper.flexCenter, {width: Device.width}]}>
                <TouchableOpacity
                  onPress={()=>{this.navigateHome('dinein')}}
                  activeOpacity={Config.activeOpacity}
                  // style={[styleHelper.blockRightHalfMargin, {alignItems: 'center'}]} >
                  style={[productStyles.customBtn, styleHelper.containerTouchableBig, styleHelper.flexRow, styleHelper.flexCenter]}>
                    {/* <View style={[styleHelper.flexCenter, {marginBottom: Device.getSize(10)}]}> */}

                      <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.fontWhite, styleHelper.font12, styleHelper.textCenter]}>
                        {this.state.orderExist ? 'ADD ORDER' : 'START ORDER'}
                      </Text>

                        {/* <Image
                          source={require('../Img/dine_In_mp2.png')}
                          style={[styleHelper.LargeIconSize2]}
                          resizeMode={'contain'} /> */}
                      {/* </View> */}
                </TouchableOpacity>

                {/* <TouchableOpacity
                  onPress={()=>{this.navigateHome('takeaway')}}
                  activeOpacity={Config.activeOpacity}
                  style={[styleHelper.blockRightHalfMargin, {alignItems: 'center'}]} >
                    <View style={[styleHelper.flexCenter, {marginBottom: Device.getSize(10)}]}>
                      <Image
                        source={require('../Img/takeaway-mp2.png')}
                        style={[styleHelper.LargeIconSize2]}
                        resizeMode={'contain'} />
                    </View>
                </TouchableOpacity> */}
              </View>

              <TouchableOpacity
                  onPress={()=>{this.navigateMyOrder()}}
                  activeOpacity={Config.activeOpacity} 
                  style={[productStyles.customBtn2, styleHelper.containerTouchableBig, styleHelper.flexRow, styleHelper.flexCenter]}>

                  <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font12, styleHelper.textCenter, {color: '#A2412B'}]}>
                      MY ORDER
                  </Text>

              </TouchableOpacity>

              <View style={[styleHelper.flexRow, styleHelper.flexCenter, {width: Device.width * 0.7, marginBottom: Device.getSize(20)}]}>
                <CheckBox
                  value={this.state.isCheck}
                  onValueChange={() => this.setState({ isCheck: !this.state.isCheck })}
                  style={[{marginRight: Device.getSize(5)}]}
                />
                <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font12, {color: '#F5DBD3'}]}>
                  I confirm I am of legal drinking age, ie aged 18 and above.
                </Text>
              </View>
          </View>
      </ImageBackground>
    );
  }
}
