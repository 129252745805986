import React from 'react';
import {StatusBar, Text, View, ScrollView, StyleSheet, TouchableOpacity} from 'react-native';

import * as Navigation from '../Routes/Routing';

import styleHelper from '../Styles/StyleHelper';

import ProductOrder from '../Components/ProductOrder';
import ImageBackground from '../Components/ImageBackground';
import OrderInfo from '../Components/OrderInfo';
import SmallSpinner from '../Components/SmallSpinner';

import API from '../Utils/API';
import Config from '../Utils/Config';
import Services from '../Utils/Services';
import Device from '../Utils/Device';

import CartActions from '../Actions/CartActions';
import OrderActions from '../Actions/OrderActions';

import CartStore from '../Stores/CartStore';
import OrderStore from '../Stores/OrderStore';

let styles = StyleSheet.create({
  container: {
    paddingTop: Device.getSize(50),
    paddingHorizontal: Device.getSize(10)
  },
  popupContainer: {
    width: Device.width * 0.8,
    minHeight: Device.height * 0.3,
    backgroundColor: '#FFF',
    paddingVertical: Device.getSize(10),
    paddingHorizontal: Device.getSize(10),
    marginTop: Device.height * 0.3,
    marginBottom: Device.getSize(20),
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: Device.getSize(10)
  },
});

export default class Confirmation extends React.Component {
    constructor(props) {
        super(props);
        this.resetCart = this.resetCart.bind(this);
        this.renderDone = this.renderDone.bind(this);
        this.renderTopNav = this.renderTopNav.bind(this);
        // this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
        this.state = {
            // orderNumber: this.props.route.params.orderNo,
            grandTotal: CartStore.getGrandTotal(),
            subTotal: CartStore.getSubtotal(),
            order: CartStore.getOrder(),
            showDone: true,
            smallSpinner: false,
            //   showPopup: this.props.route.params.queueNo.startsWith("5") ? false : true,
            // payments: [
            //     CartStore.getGrandTotal() > 0 ?
            //     {
            //         remarks: 'Credit Card',
            //         amount: CartStore.getGrandTotal()
            //     } : {},
            //     CartStore.getRewardPoints() > 0 ?
            //     {
            //         remarks: 'Souperholic Points',
            //         amount: CartStore.getRewardPoints() * 0.01
            //     } : {}
            // ]
        };
    }

    componentDidMount() {
        // BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
    }
  
//   handleBackButtonClick() {
//     this.props.navigation.goBack(null);

//     return true;
//   }

    resetCart(callback) {
        CartActions.reset();
        // CustomerStore.resetTssVoucher();
        // if(CustomerStore.hasSouperholic() === true){
        //   API.GetVoucherByFolio(CustomerStore.getCardNumber(), (resp)=>{
        //     if(resp.length > 0) {
        //       if(resp[0].Result == 'Success') {
        //         CustomerActions.setTssVouchers(resp);
        //       }
        //     }
        //   });
        // }
        // OrderViewActions.getOrdersFromServer2(true);
        callback();
    }

//   renderPopup() {
//     let date = Services.formatYMD2(new Date());
//     let time = Services.formatOpeningHour(new Date());

//     return (
//       <Modal
//         animationType="fade"
//         transparent={true}
//         visible={this.state.showPopup}>
//           <View style={[styleHelper.flex1, styles.blurBg, {alignItems: 'center'}]}>
//             <View style={[styles.popupContainer]}>
//               <View style={[{paddingVertical: Device.getSize(10), paddingHorizontal: Device.getSize(5)}]}>
//                 <Text style={[styleHelper.fontBold, styleHelper.font16]}>
//                   SOMETHING’S NOT RIGHT.
//                 </Text>
//               </View>

//               <View style={[{paddingHorizontal: Device.getSize(5)}]}>
//                 <Text style={[styleHelper.fontRegular, styleHelper.font15, {marginBottom: Device.getSize(15)}]}>
//                   But don’t worry, your payment has been made. Kindly approach our staff at the outlet with the details below to process your order.
//                 </Text>
//                 <View style={[styleHelper.borderBottomBlackTransparent, {marginBottom: Device.getSize(15)}]} />

//                 <Text style={[styleHelper.fontBold, styleHelper.font15]}>
//                   ORDER DETAILS
//                 </Text>
//                 <Text style={[styleHelper.fontRegular, styleHelper.font15]}>
//                   Date: {date}
//                 </Text>
//                 <Text style={[styleHelper.fontRegular, styleHelper.font15]}>
//                   Time: {time}
//                 </Text>
//                 <Text style={[styleHelper.fontRegular, styleHelper.font15]}>
//                   Amount: ${this.amount}
//                 </Text>
                
//                 <Text style={[styleHelper.fontRegular, styleHelper.font15]}>
//                   Item(s) ordered: 
//                 </Text>

//                 {/* <View style={[styleHelper.borderBottomBlackTransparent, {marginVertical: Device.getSize(10)}]} /> */}
//                 {this.renderItems()}
//               </View>
//             </View>
//           </View>
//       </Modal>
//     )
//   }

//   renderItems() {
//     let products = CartStore.getServerProducts();
//     return (
//       products.map((product,i) => {
//         return (
//           <View key={i}>
//             <Text style={[styleHelper.fontRegular, styleHelper.font15]}>
//               * {product.productName} x{product.quantity}
//             </Text>

//             {product.modifiers !== undefined && product.modifiers.length > 0 && product.modifiers.map((modifier,i) => {
//               if(modifier !== null) {
//                 return (
//                   <Text key={i} style={[styleHelper.fontRegular, styleHelper.font15]}>
//                     * {modifier.modifierName} x{modifier.quantity}
//                   </Text>
//                 )
//               }
//             })}
//           </View>
//         )
//       })
//     )
//   }

    renderTopNav() {
      return(
        <View style={[styleHelper.flexRow, {backgroundColor: '#ca6d4f', height: Device.getSize(50)}]}>
            <View style={[styleHelper.flex1]} />

            <View style={[styleHelper.flex1, styleHelper.flexCenter]}>
                <Text style={[styleHelper.fontWhite, styleHelper.font16, styleHelper.fontHelveticaNeueBold]}>
                    CONFIRMATION
                </Text>
            </View>

            <View style={[styleHelper.flex1]} />
        </View>
      )
    }

    renderDone() {
        return (
          <View
            style={[
              styleHelper.flexRow,
              // styleHelper.borderTopWhiteTransparent,
              styleHelper.flexCenter,
              {backgroundColor: '#a1412b', borderRadius: Device.getSize(5), marginTop: Device.getSize(10), marginHorizontal: Device.getSize(10)}
          ]}>
            <TouchableOpacity
                onPress={() => this.resetCart(() => {
                      Navigation.navigate('Welcome');
                })}
                activeOpacity={Config.activeOpacity}
                style={[
                  styleHelper.containerNormal,
                  styleHelper.flex1,
                  styleHelper.flexRow,
                  styleHelper.flexCenter,
                ]}>
                <Text
                    style={[
                        styleHelper.fontTitleBold,
                        styleHelper.fontWhite,
                        styleHelper.font16,
                        styleHelper.textCenter,
                    ]}>
                    DONE
                </Text>
            </TouchableOpacity>
          </View>
        )
    }

    render() {
      return (
        <View style={styleHelper.flex1}>
          {this.renderTopNav()}
          <ImageBackground>
            <View style={[{padding: Device.getSize(10)}]}>
              <ScrollView style={[{minHeight: Device.height * 0.83}]}>
                <SectionBlackTop
                  // orderNumber={this.state.orderNumber}
                  grandTotal={this.state.grandTotal}
                  order={this.state.order}
                />
                {/* <OrderDetails order={this.state.order} subTotal={this.state.subTotal} /> */}
              </ScrollView>
            </View>

            {
              this.state.showDone && 
                  this.renderDone()
            }
          </ImageBackground>
        </View>
      );
    }
}

const SectionBlackTop = props => {
  // if (props.orderNumber) {
    return (
      <View>
        <OrderInfo />

        <View
          style={[
            styleHelper.containerNormal,
            // styleHelper.borderBottomBlackTransparent,
          ]}>
          <Text
            style={[
              styleHelper.fontBlack,
              styleHelper.font17,
              styleHelper.fontRegular,
              styleHelper.textCenter,
              styleHelper.containerVerticalTiny,
            ]}>
            We've got your order
          </Text>

          <Text
            style={[
              styleHelper.fontBlack,
              styleHelper.font17,
              styleHelper.fontRegular,
              styleHelper.textCenter,
              styleHelper.containerVerticalTop,
            ]}>
            and will be serving you shortly
          </Text>
        </View>

      </View>

    );
  // }
  // return null;
};

const OrderDetails = props => {
  if (props.order) {
    return <ProductOrder order={props.order} subTotal={props.subTotal} />;
  }
  return null;
};
